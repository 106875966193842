
// Libraries
import * as React from 'react'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import RegisterForm from '../../../components/registerForm'

const RegisterOrganisationPage = () => (
	<Layout className="register-organisation nav-blue-half no-footer register-flow">
		<Seo title="Register Organisation" />
		<RegisterForm RegisterOrganisation process="organisation"/>
	</Layout>
)

export default RegisterOrganisationPage

